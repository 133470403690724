import React from "react";

export const MatchmakingAnimation = () => {
    return (
        <div className="container">
            <div className="box1"></div>
            <div className="box2"></div>
            <div className="box3"></div>
        </div>
    );
}